<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0">
            <i class="far fa-copyright"></i> {{ $t("HomeSeoUpdate") }}
          </h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="homeSeo.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="homeSeo.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Title -->
      <WeInput
        label="Başlık"
        v-model="form.title"
        v-bind:required="true"
        v-bind:error="$v.form.title.$error"
      />
      <!-- ./Title -->

      <div class="d-block mb-3">
        <label class="custom-label">Durum</label>
        <WeSwitch v-model="form.is_active" v-bind:show-status="true" />
      </div>
      <!-- ./Is Active -->

      <!-- description -->
      <WeEditor
        name="blog"
        v-model="form.description"
        title="İçerik"
        v-bind:accessToken="session.accessToken"
      />
      <!-- ./description -->
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: true,
      form: {
        title: null,
        description: null,
        is_active: true,
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  methods: {
    ...mapActions("homeSeo", ["create", "update", "getHomeSeo"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),

    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.homeSeo.id) {
        this.cleanRequest = this.form;
        this.updateHomeSeo();
      } else {
        this.addHomeSeo();
      }
    },

    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Anasayfa SEO güncelleme başarılı.",
        icon: "success",
        showCancelButton: false,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "OK",
      }).then((confirm) => {
        window.location.reload();
      });
    },
    addHomeSeo() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateHomeSeo() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.update({
        id: this.homeSeo.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getLangHomeSeo() {
      this.ready = false;
      this.getHomeSeo({
        onSuccess: (result) => {
          if (result && result.length > 0) {
            const data = result[0];
            this.form = data;
            this.homeSeo.id = data.id;
            this.cleanData = helper.clone(this.form);
          }
          this.ready = true;
        },
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["homeSeo", "shared", "session"]),
    checkPermission() {
      return permission.check("blog", "u");
    },
  },
  mounted() {
    this.getLangHomeSeo();
  },
  watch: {
    "homeSeo.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getLangHomeSeo();
        }
      },
    },
  },
};
</script>
